// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { PropDefRow } from '../typings';

const data = [
  {
    "id": "filter_sport",
    "dataType": "sport",
    "tileType": "square",
    "tileImageProps": "icons/sport/${id}.png",
    "isOptional": true,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "description": t('rawlingsGloves:sheets.propDefs.filter_sport.description')
  },
  {
    "id": "product_leather",
    "dataType": "leather",
    "tileType": "square",
    "tileImageProps": "icons/leather/${id}.png",
    "isOptional": true,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "leather",
    "description": t('rawlingsGloves:sheets.propDefs.product_leather.description')
  },
  {
    "id": "product_fit",
    "dataType": "fit",
    "tileType": "square",
    "tileImageProps": "icons/fit/${id}.png",
    "isOptional": true,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "fit-type",
    "description": t('rawlingsGloves:sheets.propDefs.product_fit.description')
  },
  {
    "id": "filter_position",
    "dataType": "position",
    "tileType": "square",
    "tileImageProps": "icons/position/${id}.png",
    "isOptional": true,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "description": t('rawlingsGloves:sheets.propDefs.filter_position.description')
  },
  {
    "id": "filter_size",
    "dataType": "size",
    "tileType": "squareWithText",
    "isOptional": true,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "description": t('rawlingsGloves:sheets.propDefs.filter_size.description')
  },
  {
    "id": "product_glove",
    "dataType": "glove",
    "tileType": "square",
    "tileImageProps": "icons/model/${id}.png",
    "isOptional": true,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "design-template",
    "description": t('rawlingsGloves:sheets.propDefs.product_glove.description')
  },
  {
    "id": "product_throwingHand",
    "dataType": "hand",
    "tileType": "square",
    "tileImageProps": "icons/hand/${id}.png",
    "defaultValueId": "right",
    "isOptional": false,
    "isAlwaysVisible": true,
    "viewNames": [
      "web"
    ],
    "legacyPath": "throwing-hand",
    "description": t('rawlingsGloves:sheets.propDefs.product_throwingHand.description')
  },
  {
    "id": "colors_web_style",
    "dataType": "web",
    "tileType": "square",
    "tileImageProps": "icons/web/${id}.jpg",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web",
      "palm"
    ],
    "legacyPath": "web-style",
    "description": t('rawlingsGloves:sheets.propDefs.colors_web_style.description')
  },
  {
    "id": "colors_web_color",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "camel",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "web-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_web_color.description')
  },
  {
    "id": "colors_logoPatch",
    "dataType": "patchColor",
    "tileType": "wide",
    "tileImageProps": "icons/logoPatch/a${asset.patchAssetId}.png",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "logo-patch-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_logoPatch.description')
  },
  {
    "id": "colors_shellBack_design",
    "dataType": "design",
    "tileType": "squareWithText",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "shell-back-design",
    "description": t('rawlingsGloves:sheets.propDefs.colors_shellBack_design.description')
  },
  {
    "id": "colors_shellBack_material",
    "dataType": "material",
    "tileType": "square",
    "tileImageProps": "icons/shellBackMaterials/${id}.png",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "shell-back-material",
    "description": t('rawlingsGloves:sheets.propDefs.colors_shellBack_material.description')
  },
  {
    "id": "colors_shellBack_leatherColor",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "camel",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "shell-back-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_shellBack_leatherColor.description')
  },
  {
    "id": "colors_shellPalm",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "camel",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "palm"
    ],
    "legacyPath": "shell-palm-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_shellPalm.description')
  },
  {
    "id": "colors_backPalm",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "camel",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "back-palm-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_backPalm.description')
  },
  {
    "id": "colors_laces_fingerWeb",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "black",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "finger-and-web-laces-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_laces_fingerWeb.description')
  },
  {
    "id": "colors_laces_heel",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "black",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "palm"
    ],
    "legacyPath": "heel-laces-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_laces_heel.description')
  },
  {
    "id": "colors_laces_laceLength",
    "dataType": "laceLength",
    "tileType": "squareWithText",
    "defaultValueId": "standard",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "lace-length",
    "description": t('rawlingsGloves:sheets.propDefs.colors_laces_laceLength.description')
  },
  {
    "id": "colors_lining",
    "dataType": "leatherColor",
    "tileType": "squareWithText",
    "defaultValueId": "black",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "lining-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_lining.description')
  },
  {
    "id": "colors_welting_all",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "black",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "welting-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_welting_all.description')
  },
  {
    "id": "colors_welting_palm",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "black",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "palm"
    ],
    "legacyPath": "palm-welting-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_welting_palm.description')
  },
  {
    "id": "colors_welting_back",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "black",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "back-welting-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_welting_back.description')
  },
  {
    "id": "colors_welting_handSewn",
    "dataType": "toggles",
    "tileType": "squareWithText",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "hand-sewn",
    "description": t('rawlingsGloves:sheets.propDefs.colors_welting_handSewn.description')
  },
  {
    "id": "colors_welting_handSewnStitching",
    "dataType": "leatherColor",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "hand-sewn-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_welting_handSewnStitching.description')
  },
  {
    "id": "colors_binding",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "black",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "binding-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_binding.description')
  },
  {
    "id": "colors_trim_style",
    "dataType": "trim",
    "tileType": "squareWithText",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "trim-style",
    "description": t('rawlingsGloves:sheets.propDefs.colors_trim_style.description')
  },
  {
    "id": "colors_trim_color",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "camel",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "trim-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_trim_color.description')
  },
  {
    "id": "colors_stitching",
    "dataType": "leatherColor",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "thread-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_stitching.description')
  },
  {
    "id": "colors_stamping",
    "dataType": "leatherColor",
    "tileType": "squareWithText",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "palm"
    ],
    "legacyPath": "stamping-color",
    "description": t('rawlingsGloves:sheets.propDefs.colors_stamping.description')
  },
  {
    "id": "options_fingerPadHood_fingerPad",
    "dataType": "fingerColor",
    "tileType": "squareWithText",
    "isOptional": true,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "finger-pad",
    "description": t('rawlingsGloves:sheets.propDefs.options_fingerPadHood_fingerPad.description')
  },
  {
    "id": "options_fingerPadHood_fingerHood",
    "dataType": "toggles",
    "tileType": "squareWithText",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "finger-hood",
    "description": t('rawlingsGloves:sheets.propDefs.options_fingerPadHood_fingerHood.description')
  },
  {
    "id": "options_fingerPadHood_position",
    "dataType": "fingerPosition",
    "tileType": "squareWithText",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "finger-pad-or-hood-position",
    "description": t('rawlingsGloves:sheets.propDefs.options_fingerPadHood_position.description')
  },
  {
    "id": "options_palmPad",
    "dataType": "palmPad",
    "tileType": "squareWithText",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "palm-pad",
    "description": t('rawlingsGloves:sheets.propDefs.options_palmPad.description')
  },
  {
    "id": "options_heelPad",
    "dataType": "heelPad",
    "tileType": "squareWithText",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "heel-pad",
    "description": t('rawlingsGloves:sheets.propDefs.options_heelPad.description')
  },
  {
    "id": "options_wristLining",
    "dataType": "wristLining",
    "tileType": "squareWithText",
    "defaultValueId": "default",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "wrist-lining",
    "description": t('rawlingsGloves:sheets.propDefs.options_wristLining.description')
  },
  {
    "id": "options_sweatband",
    "dataType": "toggles",
    "tileType": "squareWithText",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web"
    ],
    "legacyPath": "sweatband",
    "description": t('rawlingsGloves:sheets.propDefs.options_sweatband.description')
  },
  {
    "id": "options_breakIn",
    "dataType": "breakIn",
    "tileType": "squareWithText",
    "defaultValueId": "standard",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "break-in",
    "description": t('rawlingsGloves:sheets.propDefs.options_breakIn.description')
  },
  {
    "id": "personalization_embroidery_color",
    "dataType": "leatherColor",
    "tileType": "square",
    "defaultValueId": "black",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "embroidery.color",
    "description": t('rawlingsGloves:sheets.propDefs.personalization_embroidery_color.description')
  },
  {
    "id": "personalization_embroidery_number_text",
    "dataType": "text",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "embroidery.number-text",
    "description": t('rawlingsGloves:sheets.propDefs.personalization_embroidery_number_text.description')
  },
  {
    "id": "personalization_embroidery_number_font",
    "dataType": "font",
    "tileType": "square",
    "tileImageProps": "icons/font/${id}.jpg",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "embroidery.number-font",
    "description": t('rawlingsGloves:sheets.propDefs.personalization_embroidery_number_font.description')
  },
  {
    "id": "personalization_embroidery_logo",
    "dataType": "logo",
    "tileType": "wide",
    "tileImageProps": "icons/logo/${id}.png",
    "isOptional": true,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "embroidery.logo",
    "description": t('rawlingsGloves:sheets.propDefs.personalization_embroidery_logo.description')
  },
  {
    "id": "personalization_embroidery_thumb_text",
    "dataType": "text",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "embroidery.thumb-text",
    "description": t('rawlingsGloves:sheets.propDefs.personalization_embroidery_thumb_text.description')
  },
  {
    "id": "personalization_embroidery_thumb_font",
    "dataType": "font",
    "tileType": "square",
    "tileImageProps": "icons/font/${id}.jpg",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "web"
    ],
    "legacyPath": "embroidery.thumb-font",
    "description": t('rawlingsGloves:sheets.propDefs.personalization_embroidery_thumb_font.description')
  },
  {
    "id": "personalization_embroidery_pinky_text",
    "dataType": "text",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back"
    ],
    "legacyPath": "embroidery.pinky-text",
    "description": t('rawlingsGloves:sheets.propDefs.personalization_embroidery_pinky_text.description')
  },
  {
    "id": "personalization_embroidery_pinky_font",
    "dataType": "font",
    "tileType": "square",
    "tileImageProps": "icons/font/${id}.jpg",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back"
    ],
    "legacyPath": "embroidery.pinky-font",
    "description": t('rawlingsGloves:sheets.propDefs.personalization_embroidery_pinky_font.description')
  },
  {
    "id": "personalization_embroidery_pinkyPalm_text",
    "dataType": "text",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "palm"
    ],
    "legacyPath": "embroidery.pinkyPalm-text",
    "description": t('rawlingsGloves:sheets.propDefs.personalization_embroidery_pinkyPalm_text.description')
  },
  {
    "id": "personalization_flag",
    "dataType": "flag",
    "tileType": "wide",
    "tileImageProps": "icons/flag/${id}.png",
    "isOptional": true,
    "isAlwaysVisible": false,
    "viewNames": [
      "web",
      "back"
    ],
    "legacyPath": "embroidery.flag",
    "description": t('rawlingsGloves:sheets.propDefs.personalization_flag.description')
  },
  {
    "id": "calc_sport",
    "dataType": "sport",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "sport",
    "description": t('rawlingsGloves:sheets.propDefs.calc_sport.description')
  },
  {
    "id": "calc_position",
    "dataType": "position",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "position",
    "description": t('rawlingsGloves:sheets.propDefs.calc_position.description')
  },
  {
    "id": "calc_size",
    "dataType": "size",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "size",
    "description": t('rawlingsGloves:sheets.propDefs.calc_size.description')
  },
  {
    "id": "calc_sku",
    "dataType": "sku",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "description": t('rawlingsGloves:sheets.propDefs.calc_sku.description')
  },
  {
    "id": "legacy_sku",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "SKU",
    "description": t('rawlingsGloves:sheets.propDefs.legacy_sku.description')
  },
  {
    "id": "legacy_shellBackId",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "shell-back",
    "description": t('rawlingsGloves:sheets.propDefs.legacy_shellBackId.description')
  },
  {
    "id": "legacy_meshStyle",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "mesh-style",
    "description": t('rawlingsGloves:sheets.propDefs.legacy_meshStyle.description')
  },
  {
    "id": "legacy_indexFingerPad",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "index-finger-pad",
    "description": t('rawlingsGloves:sheets.propDefs.legacy_indexFingerPad.description')
  },
  {
    "id": "legacy_indexFingerHood",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "index-finger-hood",
    "description": t('rawlingsGloves:sheets.propDefs.legacy_indexFingerHood.description')
  },
  {
    "id": "legacy_middleFingerPad",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "middle-finger-pad",
    "description": t('rawlingsGloves:sheets.propDefs.legacy_middleFingerPad.description')
  },
  {
    "id": "legacy_middleFingerHood",
    "tileType": "square",
    "isOptional": false,
    "isAlwaysVisible": false,
    "viewNames": [
      "back",
      "web",
      "palm"
    ],
    "legacyPath": "middle-finger-hood",
    "description": t('rawlingsGloves:sheets.propDefs.legacy_middleFingerHood.description')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<PropDefRow[]>> = data;

export type PropDef = typeof typed[number];

export const PROP_DEF_INDEX_KEY = "id";
export type PropDefIndexKey = "id";
export type PropDefId = PropDef["id"];

let i = 0;
export const PROP_DEF_DICT = {
  "filter_sport": typed[i++],
  "product_leather": typed[i++],
  "product_fit": typed[i++],
  "filter_position": typed[i++],
  "filter_size": typed[i++],
  "product_glove": typed[i++],
  "product_throwingHand": typed[i++],
  "colors_web_style": typed[i++],
  "colors_web_color": typed[i++],
  "colors_logoPatch": typed[i++],
  "colors_shellBack_design": typed[i++],
  "colors_shellBack_material": typed[i++],
  "colors_shellBack_leatherColor": typed[i++],
  "colors_shellPalm": typed[i++],
  "colors_backPalm": typed[i++],
  "colors_laces_fingerWeb": typed[i++],
  "colors_laces_heel": typed[i++],
  "colors_laces_laceLength": typed[i++],
  "colors_lining": typed[i++],
  "colors_welting_all": typed[i++],
  "colors_welting_palm": typed[i++],
  "colors_welting_back": typed[i++],
  "colors_welting_handSewn": typed[i++],
  "colors_welting_handSewnStitching": typed[i++],
  "colors_binding": typed[i++],
  "colors_trim_style": typed[i++],
  "colors_trim_color": typed[i++],
  "colors_stitching": typed[i++],
  "colors_stamping": typed[i++],
  "options_fingerPadHood_fingerPad": typed[i++],
  "options_fingerPadHood_fingerHood": typed[i++],
  "options_fingerPadHood_position": typed[i++],
  "options_palmPad": typed[i++],
  "options_heelPad": typed[i++],
  "options_wristLining": typed[i++],
  "options_sweatband": typed[i++],
  "options_breakIn": typed[i++],
  "personalization_embroidery_color": typed[i++],
  "personalization_embroidery_number_text": typed[i++],
  "personalization_embroidery_number_font": typed[i++],
  "personalization_embroidery_logo": typed[i++],
  "personalization_embroidery_thumb_text": typed[i++],
  "personalization_embroidery_thumb_font": typed[i++],
  "personalization_embroidery_pinky_text": typed[i++],
  "personalization_embroidery_pinky_font": typed[i++],
  "personalization_embroidery_pinkyPalm_text": typed[i++],
  "personalization_flag": typed[i++],
  "calc_sport": typed[i++],
  "calc_position": typed[i++],
  "calc_size": typed[i++],
  "calc_sku": typed[i++],
  "legacy_sku": typed[i++],
  "legacy_shellBackId": typed[i++],
  "legacy_meshStyle": typed[i++],
  "legacy_indexFingerPad": typed[i++],
  "legacy_indexFingerHood": typed[i++],
  "legacy_middleFingerPad": typed[i++],
  "legacy_middleFingerHood": typed[i++]
} as const;

export { typed as PROP_DEFS };
