// DO NOT EDIT, GENERATED BY "yarn sheets"

import { t } from '../../../../platform/i18n'

import type { LogoRow } from '../typings';

const data = [
  {
    "id": "cloverleaf",
    "isDisabled": true,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.cloverleaf.name')
  },
  {
    "id": "1694R",
    "isDisabled": true,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.1694R.name')
  },
  {
    "id": "1695R",
    "isDisabled": true,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.1695R.name')
  },
  {
    "id": "1227R",
    "isDisabled": true,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.1227R.name')
  },
  {
    "id": "0460R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0460R.name')
  },
  {
    "id": "0380R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0380R.name')
  },
  {
    "id": "0407R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0407R.name')
  },
  {
    "id": "0511R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0511R.name')
  },
  {
    "id": "0471R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0471R.name')
  },
  {
    "id": "0453R",
    "isDisabled": true,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0453R.name')
  },
  {
    "id": "0057R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0057R.name')
  },
  {
    "id": "0378R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0378R.name')
  },
  {
    "id": "0455R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0455R.name')
  },
  {
    "id": "0408R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0408R.name')
  },
  {
    "id": "0379R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0379R.name')
  },
  {
    "id": "0454R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0454R.name')
  },
  {
    "id": "0461R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0461R.name')
  },
  {
    "id": "0493R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0493R.name')
  },
  {
    "id": "0492R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0492R.name')
  },
  {
    "id": "0512R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0512R.name')
  },
  {
    "id": "0472R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0472R.name')
  },
  {
    "id": "0223R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0223R.name')
  },
  {
    "id": "0463R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0463R.name')
  },
  {
    "id": "0409R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0409R.name')
  },
  {
    "id": "0459R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0459R.name')
  },
  {
    "id": "0468R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0468R.name')
  },
  {
    "id": "0451R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0451R.name')
  },
  {
    "id": "0452R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0452R.name')
  },
  {
    "id": "0464R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0464R.name')
  },
  {
    "id": "0491R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0491R.name')
  },
  {
    "id": "0224R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0224R.name')
  },
  {
    "id": "0457R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0457R.name')
  },
  {
    "id": "0466R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0466R.name')
  },
  {
    "id": "0510R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0510R.name')
  },
  {
    "id": "0465R",
    "isDisabled": false,
    "props": {
      "colorCount": 0
    },
    "name": t('rawlingsGloves:sheets.logos.0465R.name')
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<LogoRow[]>> = data;

export type Logo = typeof typed[number];

export const LOGO_INDEX_KEY = "id";
export type LogoIndexKey = "id";
export type LogoId = Logo["id"];

let i = 0;
export const LOGO_DICT = {
  "cloverleaf": typed[i++],
  "1694R": typed[i++],
  "1695R": typed[i++],
  "1227R": typed[i++],
  "0460R": typed[i++],
  "0380R": typed[i++],
  "0407R": typed[i++],
  "0511R": typed[i++],
  "0471R": typed[i++],
  "0453R": typed[i++],
  "0057R": typed[i++],
  "0378R": typed[i++],
  "0455R": typed[i++],
  "0408R": typed[i++],
  "0379R": typed[i++],
  "0454R": typed[i++],
  "0461R": typed[i++],
  "0493R": typed[i++],
  "0492R": typed[i++],
  "0512R": typed[i++],
  "0472R": typed[i++],
  "0223R": typed[i++],
  "0463R": typed[i++],
  "0409R": typed[i++],
  "0459R": typed[i++],
  "0468R": typed[i++],
  "0451R": typed[i++],
  "0452R": typed[i++],
  "0464R": typed[i++],
  "0491R": typed[i++],
  "0224R": typed[i++],
  "0457R": typed[i++],
  "0466R": typed[i++],
  "0510R": typed[i++],
  "0465R": typed[i++]
} as const;

export { typed as LOGOS };